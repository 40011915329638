.loginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .loginHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    img {
      width: 30%;
    }
    @media screen and (min-width: 1280px) {
      height: 200px;
      img {
        height: 50%;
        width: auto;
        margin-bottom: 20px;
      }
    }
  }

  form {
    width: 80%;
    text-align: center;
    h5 {
      font-family: $font-3;
      color: $primary;
      margin-bottom: 10px;
    }
    .loginInput {
      margin-bottom: 10px;
    }
    button {
      background-color: $primary;
      color: $white;
      margin-top: 10px;
      &:focus {
        background-color: $primary;
      }
    }
  }
}
