.header {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  img {
    height: 30%;
  }
  h1 {
    font-weight: 500;
    color: $secondary;
    font-family: $font-1;
    font-size: 3rem;
  }
  @media screen and (min-width: 1280px) {
    height: 200px;
    img {
      height: 50%;
      margin-bottom: 20px;
    }
  }
}
