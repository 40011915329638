.adminActionBar {
  height: 30px;
  width: 100%;
  display: flex;
  margin-top: 15px;
  button {
    cursor: pointer;
    height: 25px;
    display: flex;
    align-items: center;
  }
}

// modification d'un menu
.updateInfos {
  div {
    margin-bottom: 5px;
    width: 100%;
  }
}

// sppression d'un menu
.deleteConfirmModal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.502);
  display: flex;
  align-items: center;
  justify-content: center;
  .deleteMessage {
    width: 80%;
    background-color: $white;
    padding: 20px;
    border-radius: 7px;
    font-size: 14px;
    margin-top: 10px;
    font-family: $font-3;
    border: 2px solid $primary;
    color: $primary;
    text-align: center;
    div {
      padding-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        &:first-child {
          background-color: $primary;
        }
        &:nth-child(2) {
          background-color: red;
        }
      }
    }
  }
}

.menuContainer {
  @media screen and (min-width: 1280px) {
    width: 70%;
  }
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  // création d'un menu
  .createMenuAccordion {
    background-color: $white;
    box-shadow: none;
    background-color: transparent;
    font-size: 15px;
    &::before {
      background-color: transparent;
    }
    .createMenuSummary {
      border-radius: 7px;
      font-size: 14px;
      margin-top: 10px;
      border: 2px solid #be722f;
      color: #be722f;
      box-shadow: none;
      background-color: transparent;
      font-size: 15px;
      padding: 0px 16px;
      .addIcon {
        color: $primary;
      }
    }
    .createMenuDetails {
      border-bottom: 2px solid #be722f;
      padding: 15px 10px 10px 10px;
      .createMenuField {
        margin: 0;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

// action sur les sauces
.adminActionSauce {
  margin: 5px 0 15px 0;
  button {
    margin-right: 10px;
  }
}
.updateInput input {
  color: $white !important;
}

// ajout d'une sauce
.createSauce {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  label {
    color: $primary;
    &:focus {
      color: $white;
    }
  }
  input {
    color: $white;
  }
  button {
    height: 26px;
    margin-left: 10px;
    font-size: 10px;
  }
}

// modale de suppression
.deleteSauceBackdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.502);
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .deleteSauceModale {
    width: 80%;
    background-color: $white;
    padding: 20px;
    border-radius: 7px;
    font-size: 14px;
    margin-top: 10px;
    font-family: $font-3;
    border: 2px solid $primary;
    color: $primary;
    text-align: center;
    div {
      padding-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        &:first-child {
          background-color: $primary;
        }
        &:nth-child(2) {
          background-color: red;
        }
      }
    }
  }
}

// menu caché
.hiddenMenuBox {
  display: none !important;
}
// menu suspendu
.greyMenuBox {
  background-color: rgba(0, 0, 0, 0.1);
}

.menuBox {
  height: fit-content;

  .dragItem {
    @media screen and (min-width: 1280px) {
      top: 0;
      bottom: unset;
      left: -3.2em;
      right: unset;
      height: 100%;
    }

    position: absolute;
    top: unset;
    bottom: 0.5em;
    left: unset;
    right: 0.5em;
    width: 3em;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    touch-action: none;

    svg {
      height: unset;
      width: unset;
      cursor: pointer;
      padding: 1em 0.5em;
      border-radius: 0.5em;

      &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}