@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/worstveldsling.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/Poppins-Regular.ttf),
    url(../assets/fonts/poppins-regular-webfont.woff2),
    url(../assets/fonts/poppins-regular-webfont.woff);
}
@font-face {
  font-family: "font-3";
  src: url(../assets/fonts/Poppins-Bold.ttf),
    url(../assets/fonts/poppins-bold-webfont.woff),
    url(../assets/fonts/poppins-bold-webfont.woff2);
}

$font-1: "font-1";
$font-2: "font-2";
$font-3: "font-3";

$primary: #be722f;
$secondary: #453218;
$tercery: #453218;
$black: rgb(0, 0, 0);
$white: #f5f5f5;
$shadow: rgb(219, 218, 218);
$red: #e93227;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;
  user-select: none;
  scroll-behavior: smooth;
  &::selection {
    background: $primary;
  }
}

html,
body {
  height: 100%;
}

a,
a:visited {
  color: $primary;
}
input,
textarea {
  resize: none;
  width: 100%;
  font-family: $font-1;
  background: $white;
}
textarea {
  &:focus {
    box-shadow: 0 0 4px rgba(0, 22, 44, 0.1);
  }
}
button,
input[type="submit"] {
  background: $secondary;
  color: $primary;
  font-family: $font-2;
  cursor: pointer;
  transition: 0.2s;
  margin: 0;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
  &:hover {
    background: $primary;
    color: $secondary;
  }
}
input[type="checkbox"] {
  box-shadow: none;
  width: 20px;
  margin: 10px 0;
}
i {
  transition: 0.15s;
  &:hover {
    transform: scale(1.1);
  }
}
body {
  font-family: $font-2;
  line-height: 1.4rem;
  background: $white;
  color: $black;
}
.error {
  color: red;
  font-family: $font-3;
  text-align: start;
  margin-bottom: 15px;
}
.success {
  color: green;
  font-family: $font-3;
  text-align: start;
  margin-bottom: 15px;
}
