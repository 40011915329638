.formules {
  background-color: $white;
  box-shadow: none;
  background-color: transparent;
  font-size: 15px;
  &::before {
    background-color: transparent !important;
  }
  .icon {
    color: $white;
  }
  .summaryDrawer {
    border-radius: 7px;
    font-size: 14px;
    margin-top: 10px;
    background-color: $tercery;
    border: 2px solid $tercery;
    color: $white;
    .rubriqueDrawer {
      display: inline-flex;
    }
    .rubriqueDrawer:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../assets/bouclier_brennus.png) no-repeat;
      background-size: 100%;
    }
  }
  .menuBox {
    border-bottom: 2px solid $primary;
    padding: 15px 10px 10px 10px;
  }
  .detailsDrawer {
    border-radius: 7px;
    padding: 0;
    .descriptionDrawer {
      display: flex;

      .infoMenu {
        display: flex;
        width: 100%;
      }

      .titleAndDetails {
        width: 85%;
        span {
          font-family: $font-3;
        }
        p {
          font-size: 15px;
        }
        .top {
          background-color: $red;
          color: $white;
          padding: 1px 5px;
          border-radius: 7px;
          margin-left: 5px;
          font-size: 12px;
          white-space: nowrap;
        }
      }
      .price {
        width: 15%;
        padding-left: 10px;
        span {
          font-family: $font-3;
        }
      }
    }
  }
}
