.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuContainer {
  @media screen and (min-width: 1280px) {
    width: 70%;
  }
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  .standard {
    background-color: $white;
    box-shadow: none;
    background-color: transparent;
    font-size: 15px;
    &::before {
      background-color: transparent;
    }
    .icon {
      color: $primary;
    }
    .summaryDrawer {
      border-radius: 7px;
      font-size: 14px;
      margin-top: 10px;
      border: 2px solid $primary;
      color: $primary;
      .rubriqueDrawer {
        display: inline-flex;
      }
      .rubriqueDrawer:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(../assets/bouclier_brennus.png) no-repeat;
        background-size: 100%;
      }
    }
    .menuBox {
      border-bottom: 2px solid $primary;
      padding: 15px 10px 10px 10px;
    }
    .detailsDrawer {
      border-radius: 7px;
      padding: 0;
      .descriptionDrawer {
        display: flex;

        .infoMenu {
          display: flex;
          width: 100%;
        }

        .titleAndDetails {
          width: 80%;
          span {
            font-family: $font-3;
          }
          p {
            font-size: 15px;
          }
          .top {
            background-color: $red;
            color: $white;
            padding: 1px 5px;
            border-radius: 7px;
            margin-left: 5px;
            font-size: 12px;
            white-space: nowrap;
          }
        }
        .price {
          width: 20%;
          padding-left: 10px;
          text-align: end;
          span {
            font-family: $font-3;
          }
        }
      }
    }
  }
  .formules {
    box-shadow: none;
    font-size: 15px;
    background-color: transparent;
    &::before {
      background-color: transparent !important;
    }
    .icon {
      color: $white;
    }
    .summaryDrawer {
      border-radius: 7px;
      font-size: 14px;
      margin-top: 10px;
      background-color: $tercery;
      border: 2px solid $tercery;
      color: $white;
      .rubriqueDrawer {
        display: inline-flex;
      }
      .rubriqueDrawer:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(../assets/bouclier_brennus.png) no-repeat;
        background-size: 100%;
      }
    }
    .menuBox {
      border-bottom: 2px solid $primary;
      padding: 15px 10px 10px 10px;
    }
    .detailsDrawer {
      border-radius: 7px;
      padding: 0;
      .descriptionDrawer {
        display: flex;

        .infoMenu {
          display: flex;
          width: 100%;
        }

        .titleAndDetails {
          width: 80%;
          span {
            font-family: $font-3;
          }
          p {
            font-size: 15px;
          }
          .top {
            background-color: $red;
            color: $white;
            padding: 1px 5px;
            border-radius: 7px;
            margin-left: 5px;
            font-size: 12px;
            white-space: nowrap;
          }
        }
        .price {
          width: 20%;
          padding-left: 10px;
          text-align: end;
          span {
            font-family: $font-3;
          }
        }
      }
    }
  }
  .aperos {
    background-color: $white;
    box-shadow: none;
    background-color: transparent;
    font-size: 15px;
    &::before {
      background-color: transparent !important;
    }
    .icon {
      color: $tercery;
    }
    .summaryDrawer {
      border-radius: 7px;
      font-size: 14px;
      margin-top: 10px;
      background-color: $white;
      border: 2px solid $tercery;
      color: $tercery;
      .rubriqueDrawer {
        display: inline-flex;
      }
      .rubriqueDrawer:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(../assets/bouclier_brennus.png) no-repeat;
        background-size: 100%;
      }
    }
    .menuBox {
      border-bottom: 2px solid $primary;
      padding: 15px 10px 10px 10px;
    }
    .detailsDrawer {
      border-radius: 7px;
      padding: 0;
      .descriptionDrawer {
        display: flex;

        .infoMenu {
          display: flex;
          width: 100%;
        }

        .titleAndDetails {
          width: 80%;
          span {
            font-family: $font-3;
          }
          p {
            font-size: 15px;
          }
          .top {
            background-color: $red;
            color: $white;
            padding: 1px 5px;
            border-radius: 7px;
            margin-left: 5px;
            font-size: 12px;
            white-space: nowrap;
          }
        }
        .price {
          width: 20%;
          padding-left: 10px;
          text-align: end;
          span {
            font-family: $font-3;
          }
        }
      }
    }
  }
  .wine {
    background-color: $primary;
    color: $white;
    text-align: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    height: 52px;
    padding: 10px 15px;
  }
}
.hiddeFormule {
  display: none;
}
.sauceBox {
  background-color: $secondary;
  font-size: 15px;
  color: $white;
  padding: 15px 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  h5 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  ul {
    font-size: 14px;
    font-weight: light;
  }
}
.tarifSauce {
  display: flex;
  justify-content: space-between;
  font-family: $font-3;
}

// loader
.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}
